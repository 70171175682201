import { render, staticRenderFns } from "./RelFaturamento.vue?vue&type=template&id=53c98aee&"
import script from "./RelFaturamento.vue?vue&type=script&lang=js&"
export * from "./RelFaturamento.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Source\\openproject\\go-care\\Frontend\\app\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('53c98aee')) {
      api.createRecord('53c98aee', component.options)
    } else {
      api.reload('53c98aee', component.options)
    }
    module.hot.accept("./RelFaturamento.vue?vue&type=template&id=53c98aee&", function () {
      api.rerender('53c98aee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/relatorios/RelFaturamento.vue"
export default component.exports